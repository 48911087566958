var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-box" }, [
    _c(
      "div",
      { staticClass: "section-content" },
      [
        _c("div", { staticClass: "sub-title" }, [_vm._v("售后订单")]),
        _c(
          "el-table",
          {
            staticStyle: { "margin-bottom": "18px" },
            attrs: {
              border: "",
              "highlight-current-row": "",
              "header-cell-class-name": "normal-table-header",
              "cell-style": { fontSize: "13px", color: "#666" },
              "header-cell-style": {
                paddingLeft: "15px",
                fontSize: "13px",
                color: "#000"
              },
              data: _vm.saleOrderTable
            }
          },
          [
            _vm._l(_vm.saleOrderColumns, function(item) {
              return [
                item.prop === "allPay"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var allPay = ref.row.allPay
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.allPayMap[allPay]) + " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : item.prop === "createTime"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        width: "190"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var createTime = ref.row.createTime
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate2")(
                                        createTime,
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : item.prop === "completionTime"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        width: "180"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var completionTime = ref.row.completionTime
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate2")(
                                        completionTime,
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                    )
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : item.prop === "orderStatus"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        width: "160"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.orderStatusMap[row.orderStatus])
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : item.prop === "do"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        fixed: item.fixed,
                        width: "120px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "seeBtn",
                                    staticStyle: {
                                      color: "#337AFF",
                                      cursor: "pointer"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.goDetail(row)
                                      }
                                    }
                                  },
                                  [_vm._v("查看详情")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : item.prop === "goodsName"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        prop: item.prop,
                        width: item.width || "160px",
                        fixed: item.fixed
                      }
                    })
                  : item.prop === "mobile"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        prop: item.prop,
                        width: item.width || "160px",
                        fixed: item.fixed
                      }
                    })
                  : item.prop === "nickName"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        prop: item.prop,
                        width: item.width || "120px",
                        fixed: item.fixed
                      }
                    })
                  : item.prop === "idCard"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        prop: item.prop,
                        width: item.width || "200px",
                        fixed: item.fixed
                      }
                    })
                  : item.prop === "goodsPrice"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        prop: item.prop,
                        width: item.width || "120px",
                        fixed: item.fixed
                      }
                    })
                  : item.prop === "totalMoney"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        prop: item.prop,
                        width: item.width || "120px",
                        fixed: item.fixed
                      }
                    })
                  : item.prop === "amountReceived"
                  ? _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        prop: item.prop,
                        width: item.width || "120px",
                        fixed: item.fixed
                      }
                    })
                  : _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        "show-overflow-tooltip": true,
                        label: item.label,
                        prop: item.prop,
                        width: item.width || "160px",
                        fixed: item.fixed
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                [
                                  "goodsSeriesName",
                                  "commodityName",
                                  "goodsSpecName",
                                  "productName",
                                  "goodsTypeName",
                                  "goodsCategoryName",
                                  "schoolName",
                                  "teacherRecruitName",
                                  "afterSaleTypes"
                                ].includes(item.prop)
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm._f("empty")(row[item.prop]))
                                      )
                                    ])
                                  : _c("span", [_vm._v(_vm._s(row[item.prop]))])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
              ]
            }),
            _vm.pageData.orderOriginType !== _vm.OrderOriginTypeEnum.nc
              ? _c("el-table-column", {
                  attrs: {
                    "show-overflow-tooltip": true,
                    label: "操作",
                    fixed: "right",
                    width: "120px"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "a",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.goDetail(row)
                                  }
                                }
                              },
                              [_vm._v("查看详情")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3813763621
                  )
                })
              : _vm._e()
          ],
          2
        )
      ],
      1
    ),
    _c("div", { staticClass: "section-line" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }