<template>
	<!-- 售后订单 -->
	<div class="section-box">
		<div class="section-content">
			<div class="sub-title">售后订单</div>
			<el-table
				border
				highlight-current-row
				style="margin-bottom: 18px;"
				header-cell-class-name="normal-table-header"
				:cell-style="{fontSize: '13px', color:'#666'}"
				:header-cell-style="{paddingLeft:'15px', fontSize: '13px', color:'#000'}"
				:data="saleOrderTable"
			>
				<template v-for="item of saleOrderColumns">
					<el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'allPay'" :key="item.prop" :label="item.label">
						<template v-slot:default="{ row: { allPay } }">
							{{ allPayMap[allPay] }}
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'createTime'" :key="item.prop" :label="item.label" width="190">
						<template v-slot:default="{ row: { createTime } }">
							<p>{{ createTime | formatDate2('YYYY-MM-DD HH:mm:ss')  }}</p>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'completionTime'" :key="item.prop" :label="item.label" width="180">
						<template v-slot:default="{ row: { completionTime } }">
							<p>{{ completionTime | formatDate2('YYYY-MM-DD HH:mm:ss')  }}</p>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderStatus'" :key="item.prop" :label="item.label" width="160">
						<template v-slot:default="{ row }">
							<p>{{ orderStatusMap[row.orderStatus] }}</p>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'do'" :key="item.prop" :label="item.label" :fixed="item.fixed"  width="120px">
						<template v-slot:default="{ row }">
							<div style="color: #337AFF; cursor: pointer" class="seeBtn" @click="goDetail(row)">查看详情</div>
						</template>
					</el-table-column>
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'goodsName'"
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						:width="item.width || '160px'"
						:fixed="item.fixed"
					/>
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'mobile'"
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						:width="item.width || '160px'"
						:fixed="item.fixed"
					/>
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'nickName'"
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						:width="item.width || '120px'"
						:fixed="item.fixed"
					/>
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'idCard'"
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						:width="item.width || '200px'"
						:fixed="item.fixed"
					/>
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'goodsPrice'"
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						:width="item.width || '120px'"
						:fixed="item.fixed"
					/>
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'totalMoney'"
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						:width="item.width || '120px'"
						:fixed="item.fixed"
					/>
					<el-table-column :show-overflow-tooltip="true"
						v-else-if="item.prop === 'amountReceived'"
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						:width="item.width || '120px'"
						:fixed="item.fixed"
					/>
					<el-table-column :show-overflow-tooltip="true"
						v-else
						:key="item.prop"
						:label="item.label"
						:prop="item.prop"
						:width="item.width || '160px'"
						:fixed="item.fixed"
					>
						<template v-slot:default="{row}">
							<span v-if="['goodsSeriesName', 'commodityName', 'goodsSpecName', 'productName', 'goodsTypeName', 'goodsCategoryName', 'schoolName', 'teacherRecruitName', 'afterSaleTypes'].includes(item.prop)">{{row[item.prop] | empty}}</span>
							<span v-else>{{ row[item.prop] }}</span>
						</template>
					</el-table-column>
				</template>
					<el-table-column :show-overflow-tooltip="true" label="操作" fixed="right" width="120px" v-if="pageData.orderOriginType !== OrderOriginTypeEnum.nc">
					<template v-slot:default="{ row }">
						<a type="primary" @click="goDetail(row)">查看详情</a>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="section-line"></div>
	</div>	
</template>

<script>
import { allPayMap, OrderOriginTypeEnum, orderStatusMap } from '@/views/order/constants'
export default {
	props: {
		saleOrderTable: Array,
		pageData: Object
	},
	data(){
		return {
			saleOrderColumns: [
        { label: "订单号", prop: "orderNo", fixed: true },
        { label: "报读商品", prop: "goodsName", fixed: true },
        { label: "班型", prop: "goodsSpecName" },
        { label: "产品线", prop: "productName" },
        { label: "商品分类", prop: "goodsCategoryName" },
        { label: "产品系列", prop: "goodsSeriesName" },
        { label: "学习帐号", prop: "mobile" },
        { label: "学员姓名", prop: "nickName" },
        { label: "身份证号", prop: "idCard" },
        { label: "客户编号", prop: "customerCode" },
        { label: "应收金额", prop: "totalMoney" },
        { label: "已收金额", prop: "amountReceived" },
        { label: "订单状态", prop: "orderStatus" },
        { label: "售后", prop: "afterSaleTypes" }, //二期新增
        { label: "招生老师", prop: "teacherRecruitName" },
        { label: "上课校区", prop: "signSchoolName" },
        { label: "报名时间", prop: "createTime" },
      ],
			allPayMap,
			orderStatusMap,
			OrderOriginTypeEnum,
		}
	},
	methods: {
		goDetail(row){
			this.$emit('goDetail', row)
		}
	}
}
</script>

<style lang="scss" scoped>
  .section-box{
    .section-content{
      width: 94%;
      margin: 0 auto;
      padding-top: 18px;
      padding-bottom: 18px;
    }
    .sub-title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding-bottom: 13px;
    }
    .cell p{
      margin-bottom: 0;
    }
    .section-line{
      height: 12px;
      background: #F5F5FA;
    }
  }
</style>